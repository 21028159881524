/**
 * @license
 * Copyright© Celligence International, LLC - 2023
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
*/
import styled, { keyframes } from 'styled-components';
// import previousButton from '../../images/new/previousButton.png';
// import nextButton from '../../images/new/nextButton.png';

export const fluctuate = keyframes`
0%{transform:scale(0.95);}
50%{transform:scale(1.05);}
100%{transform:scale(0.95);}
`;
export const Close = styled.img`
  padding: 0.75vw 0.75vw 0.75vw 0.75vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  /*   margin-top: -0.5vw;
  margin-right: -0.5vw; */
  @media (min-width: 600px) {
    background: white;
    margin-top: -2vw;
    margin-right: -2vw;
  }
  &:hover {
    @media (min-width: 600px) {
      background: #FF9823;
    }
    z-index: 10;
    animation: ${fluctuate} 1.3s infinite ease-in-out;
  }
`;
export const VideoBox = styled.div`
  padding: 1vw;
  display: flex;
  justify-content: flex-end;
  align-item: flex-start;
  background-color: rgb(45, 45, 45, 0.8);
  width: 90%;
  height: 50vw;
  @media (min-width: 600px) {
    max-width: 48rem;
    width: 90%;
    max-height: 27.5rem;
    height: 50.5vw;
  }
  @media (min-width: 1000px) {
    max-width: 35vw;
    max-height: 25vw;
  }
`;
export const VideoMain = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.videoToggle ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 10;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: rgb(88, 88, 88, 0.8);
  &::::-webkit-scrollbar {
    display: none;
  }
`;
const StyledTips = styled.section`
  width: 100%;
  padding: 0;
  
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
 & .favunderline{
  width:35%;
 }
  & .carousel-button-group {
    transform: translate(0, -140px);
  }


  & .redPlay {
    position: absolute;
    z-index: 10;
    width: 3rem;
    top: 11.4vw;
    left: 0.8vw;
  }

  & .img-hover {
    cursor: pointer;
  }
  & .carousel-image {
    max-height: 300px;
  }
  & .videos-title{
    font-size: 1.5rem;
    color: #083F88;
    font-family: 'Century Gothic';
  }
  & .img-thumbnail {
    padding: 0.25rem;
    background-color: transparent;
    border: none;
    border-radius: 0.82rem;
    max-width: 100%;
    height: auto;
}
  & .img-hover:hover .redPlay {
    animation: ${fluctuate} 1.3s infinite ease-in-out;
  }
  & .image-item {
    padding: 0 0.5rem;
  }
  @media (max-width: 1651px) {
    & .redPlay{
      width: 3rem;
      top: 12.4vw;
      left: 0.7vw;
    }
  }
  @media (max-width: 1551px) {
    & .redPlay{
      width: 3rem;
      top: 13.4vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 1425px) {
  //   & .previousButton {
  //     transform: translate(-50px, 0);
  //   }
  //   & .nextButton {
  //     transform: translate(50px, 0);
  //   }
  // }
  // @media (max-width: 1400px) {
  //   & .previousButton {
  //     transform: translate(-60px, 0);
  //   }
  //   & .nextButton {
  //     transform: translate(60px, 0);
  //   }
    & .redPlay{
      width: 3rem;
      top: 14vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 1399px) {
    & .redPlay{
      width: 3rem;
      top: 12vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 1300px) {
    & .redPlay{
      width: 3rem;
      top: 13vw;
      left: 0.8vw;
    }
  }

  @media (max-width: 1200px) {
    & .carousel-button-group {
      transform: translate(0, -135px);
    }

    & .redPlay{
      width: 3rem;
      top: 12.8vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 1199px) {
    & .redPlay{
      width: 3rem;
      top: 10.8vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 1100px) {
    & .redPlay{
      width: 3rem;
      top: 11.8vw;
      left: 0.8vw;
    }
  }
  @media (max-width: 991px) {
    & .redPlay{
      width: 3rem;
      top: 14.4vw;
      left: 0.9vw;
    }
  }
  @media (max-width: 899px) {
    & .redPlay{
      width: 3.7rem;
      top: 15.2vw;
      left: 0.9vw;
    }
  }
  @media (max-width: 767px) {
    & .carousel-button-group {
      transform: translate(0, -120px);
    }
    & .redPlay{
      width: 2.5rem;
      top: 12.5vw;
      left: 3.9vw;
    }
    & .img-thumbnail {
      max-width:85%;
    }
  }
  @media (max-width: 699px) {
    & .redPlay{
      width: 2.5rem;
      top: 14.5vw;
      left: 4.3vw;
    }
  }
  @media (max-width: 600px) {
    & .redPlay{
      width: 4rem;
      top: 34vw;
      left: 8.9vw;
    }
  }
  @media (max-width: 575px) {
    & .carousel-button-group {
      transform: translate(0, -130px);
    }
    
    & .redPlay{
      width: 3.5rem;
      top: 36.9vw;
      left: 11.3vw;
    }
  }
  @media (max-width: 545px) {
    & .redPlay{
      width: 3.5rem;
      top: 38.5vw;
      left: 9.3vw;
    }
  }
  @media (max-width: 475px) {
    
    & .redPlay{
      width: 3rem;
      top: 38.8vw;
      left: 9.3vw;
    }
    
  }
  @media (max-width: 425px) {
    & .redPlay{
      width: 2.6rem;
      top: 38.8vw;
      left: 9.3vw;
    }
  }
  @media (max-width: 375px) {
    & .redPlay{
      width: 2rem;
      top: 39.2vw;
      left: 9.9vw;
    }
  }
  @media (max-width: 328px) {
    & .redPlay{
      width: 2rem;
      top: 37vw;
      left: 9.9vw;
    }
  }
  @media (max-width: 991px) {
    & .favunderline{
      display:none;
     }
    & .favunderline1{
      max-width:100%;
     }
  }
  
`;


export const StyledModal = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  z-index: 5;
  overflow: auto;
  height: auto;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: white;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 575px) {
    background: rgb(88, 88, 88, 0.8);
  }
  @media only screen and (min-height: 675px) and (min-width: 700px) {
    align-items: center;
  }`;

  export default StyledTips;